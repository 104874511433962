import BigNumber from 'bignumber.js'
import React from 'react'

import { Address, TokenV3 } from '../../../model'
import { getMarketDisplayName, getRoundedValue, isTitleNaN } from '../../../utils'

interface BalanceButtonProps {
  account?: Address | null
  tokenFrom?: TokenV3
  walletNetwork?: string
  userBalance: BigNumber
  onPercentButtonClick: (value: number) => void
}

const BalanceButton = ({
  account,
  tokenFrom,
  walletNetwork,
  userBalance,
  onPercentButtonClick,
}: BalanceButtonProps): React.ReactElement => {
  return (
    <>
      {account && tokenFrom && tokenFrom?.network === walletNetwork && (
        <span
          role="button"
          onClick={(): void => {
            onPercentButtonClick(1)
          }}
          className="value"
          title={isTitleNaN(userBalance.gt(0) ? userBalance.toFixed() : 0)}>
          {getRoundedValue(userBalance.toFixed(), 4)}
          &nbsp;
          <span
            className="sign"
            onClick={(): void => {
              onPercentButtonClick(1)
            }}
            title={getMarketDisplayName(tokenFrom)}>
            {getMarketDisplayName(tokenFrom)}
          </span>
        </span>
      )}
    </>
  )
}

export default BalanceButton
