import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'

import { TokenV3 } from '../../../model'
import { getGasBufferForNetwork, isNativeTokenForNetwork } from '../../../utils'

interface PercentButtonsProps {
  tokenFrom?: TokenV3
  userBalance: BigNumber
  disabled: boolean
  onPercentButtonClick: (value: number) => void
}

const PercentButtons = ({
  onPercentButtonClick,
  tokenFrom,
  userBalance,
  disabled,
}: PercentButtonsProps): React.ReactElement => {
  const [isDisabled, setDisabled] = useState(true)

  useEffect(() => {
    if (!tokenFrom?.id) {
      return
    }
    const _isDisabled =
      isNativeTokenForNetwork(tokenFrom) &&
      userBalance.isLessThan(getGasBufferForNetwork(tokenFrom.network))
    setDisabled(userBalance.isLessThanOrEqualTo(0) || _isDisabled || disabled)
    // TODO:
    // https://app.shortcut.com/dexguru/story/21243/chore-components-percentbuttons-tsx-12
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFrom?.id, userBalance, disabled])

  const buttonClickHandler = (value: number) => (): void => {
    onPercentButtonClick(value)
  }

  return (
    <div className="tradeform-field__presets">
      <button
        className="button button--xs tradeform-field__preset"
        onClick={buttonClickHandler(0.25)}
        disabled={isDisabled}
        data-testid="percent-button">
        25%
      </button>
      <button
        className="button button--xs tradeform-field__preset"
        onClick={buttonClickHandler(0.5)}
        disabled={isDisabled}
        data-testid="percent-button">
        50%
      </button>
      <button
        className="button button--xs tradeform-field__preset"
        onClick={buttonClickHandler(0.75)}
        disabled={isDisabled}
        data-testid="percent-button">
        75%
      </button>
      <button
        className="button button--xs tradeform-field__preset"
        onClick={buttonClickHandler(1)}
        disabled={isDisabled}
        data-testid="percent-button">
        100%
      </button>
    </div>
  )
}

export default PercentButtons
