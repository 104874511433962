import React, { useEffect, useReducer, useRef } from 'react'
import { AnyAction } from 'redux'

import {
  decorator,
  digitsOnly,
  finalDecorator,
  unformat,
} from '../../../helpers/priceAndAmountHelpers'
import { isTitleNaN, numberWithCommas } from '../../../utils'

interface FormFromProps {
  value?: string
  disabledInput: boolean
  onChange: (value: string) => void
  onBlur: () => void
  onFocus: () => void
}
interface InputState {
  value: string
  decoratedValue: string
}

let timer: ReturnType<typeof setTimeout> | null = null

const initState: InputState = { value: '', decoratedValue: '' }

const reducer = (state: InputState, action: AnyAction): InputState => {
  switch (action.type) {
    case 'change':
      return { value: unformat(action.value), decoratedValue: decorator(action.value) }
    case 'change@decorator':
      return { ...state, decoratedValue: finalDecorator(action.value) }
    default:
      return { ...state }
  }
}

const InputFromMobile = ({
  value,
  disabledInput,
  onChange,
  onBlur,
  onFocus,
}: FormFromProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)
  const spanRef = useRef<HTMLSpanElement>(null)
  const [state, dispatch] = useReducer(reducer, initState)

  const changeHandler = (value: string): void => {
    dispatch({ type: 'change', value })
  }

  useEffect(() => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      dispatch({ type: 'change@decorator', value: state.decoratedValue })
      onChange(digitsOnly(state.value))
    }, 1000)

    return (): void => {
      if (timer) {
        clearTimeout(timer)
      }
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  useEffect((): void => {
    if (value === state.value) {
      return
    }

    dispatch({ type: 'change', value })
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <input
        className="tradeform-field__input"
        type="text"
        step="any"
        title={isTitleNaN(numberWithCommas(state.decoratedValue))}
        onChange={({ target }): void => {
          changeHandler(target.value)
        }}
        onBlur={(): void => {
          spanRef.current?.focus()
          onBlur()
        }}
        onFocus={(): void => {
          onFocus()
        }}
        placeholder="0.0"
        value={state.decoratedValue}
        ref={inputRef}
        disabled={disabledInput}
        data-testid="tradeform-input-from"
      />
      <span ref={spanRef} tabIndex={0} />
    </>
  )
}

export default React.memo(InputFromMobile, (oldProps, newProps) => {
  if (oldProps.value !== newProps.value) {
    return false
  }

  return true
})
