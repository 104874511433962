import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { financialFormat } from '../../../helpers/priceAndAmountHelpers'
import { mapToDgWrappedToken } from '../../../helpers/wrappedTokenHelpers'
import { State } from '../../../reducers'
import { getMarketDisplayName } from '../../../utils'
import InputFrom from './InputFrom'

interface FormLimitProps {
  limitOrderRate?: BigNumber
  isEmpty?: boolean
  onChange: (value: string) => void
}

export const FormLimit: FC<FormLimitProps> = ({ limitOrderRate, isEmpty, onChange }) => {
  const { currentToken, quoteToken } = useSelector((state: State) => state.tokens)
  const [isFocus, setIsFocus] = useState(false)

  if (!quoteToken || !currentToken) {
    return null
  }
  const onChangeHandler = (value: string): void => {
    if (getValue() === value) {
      return
    }
    onChange(value)
  }

  const getValue = (): string => {
    if (isEmpty) {
      return ''
    }

    return financialFormat(limitOrderRate?.toFixed() || '', {
      highPrecession: true,
      decimals: limitOrderRate?.lt(1) ? 7 : 4,
    })
  }

  const onSetMarketPrice = (): void => {
    onChange('')
  }

  const desiredUsdPrice = limitOrderRate?.times(quoteToken.priceUSD)
  const deltaTokenUsdPrice = desiredUsdPrice
    ?.minus(currentToken.priceUSD)
    .div(currentToken.priceUSD)

  const tokenDisplayName = getMarketDisplayName(mapToDgWrappedToken(currentToken))
  return (
    <div className="tradeform-field tradeform-field--from">
      <div className="tradeform-field__header">
        <div className="tradeform-field__title">Specify {tokenDisplayName} target price</div>
        <div className="tradeform-field__value">
          <span role="button" className="link" onClick={onSetMarketPrice}>
            Set to market
          </span>
        </div>
      </div>
      <div className="tradeform-field__body">
        <div
          className={classNames('tradeform-combo', {
            'tradeform-combo--focus': isFocus,
          })}>
          <div className="tradeform-combo__quote">1 {tokenDisplayName} =</div>

          <div className="tradeform-combo__query">
            {desiredUsdPrice?.gt(0) && (
              <div className="tradeform-field__price">
                {financialFormat(desiredUsdPrice, { prefix: '~$' })}
                &nbsp;
                {deltaTokenUsdPrice && (
                  <span
                    className={classNames('percent', {
                      'percent--gain': deltaTokenUsdPrice.gt(0),
                      'percent--loss': deltaTokenUsdPrice.lte(0),
                    })}
                    title={deltaTokenUsdPrice.toFixed()}>
                    ({financialFormat(deltaTokenUsdPrice, { decimals: 2 })}%)
                  </span>
                )}
              </div>
            )}
            <InputFrom
              onChange={onChangeHandler}
              value={getValue()}
              disabledInput={false}
              onFocus={(): void => setIsFocus(true)}
              onBlur={(): void => setIsFocus(false)}
            />
          </div>
          <div className="tradeform-combo__quote">{getMarketDisplayName(quoteToken)}</div>
        </div>
      </div>
    </div>
  )
}

export default FormLimit
