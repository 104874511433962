import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { FC, Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAuth } from '../../../containers/AuthWrapper'
import { financialFormat } from '../../../helpers/priceAndAmountHelpers'
import {
  Address,
  TokenV3,
  TokenWithApprovalContext,
  TradeformType,
  TradeType,
} from '../../../model'
import { State } from '../../../reducers'
import { getInlineNetworkIconStyle, getMarketDisplayName, getNetworkConfig } from '../../../utils'
import IconTokenWrapper from '../../IconTokenWrapper'
import { calculateValue, FormVariants } from '../helpers'
import BalanceButton from './BalanceButton'
import InputFrom from './InputFrom'
import PercentButtons from './PercentButtons'

const DropdownForm = React.lazy(() => import('../../DropdownForm'))

interface FormFromProps {
  account?: Address | null
  tokenFrom?: TokenV3
  walletNetwork?: string
  userBalance: BigNumber
  tradeType: TradeType
  isEmpty: boolean
  disabled: boolean
  disabledInput: boolean
  formVariant: TradeformType
  onFromInputChangeStr: (value: BigNumber) => void
}

const FormFrom: FC<FormFromProps> = ({
  account,
  tokenFrom,
  walletNetwork,
  userBalance,
  tradeType,
  isEmpty,
  disabled,
  disabledInput,
  formVariant,
  onFromInputChangeStr,
}: FormFromProps) => {
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [fromTokenUsdPrice, setFromTokenUsdPrice] = useState(new BigNumber(0))
  const { openProviderMenu } = useAuth()
  const currentToken: TokenWithApprovalContext | undefined = useSelector(
    (state: State) => state.tokens.currentToken
  )
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    setInputValue('')
  }, [tokenFrom?.id])

  useEffect(() => {
    if (inputValue === '') {
      return onFromInputChangeStr(new BigNumber(0))
    }
    onFromInputChangeStr(new BigNumber(inputValue))
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  useEffect(() => {
    if (isEmpty && inputValue !== '0' && inputValue !== '') {
      setInputValue('')
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty])

  const onPercentButtonClick = (value: number): void => {
    if (!tokenFrom?.id) {
      return
    }

    if (!account) {
      openProviderMenu()
      return
    }

    const { fixedValue } = calculateValue(value, tokenFrom, userBalance) as {
      percent: number
      fixedValue: string
    }

    setInputValue(fixedValue)
  }

  useEffect(() => {
    if (tokenFrom?.priceUSD) {
      const bigValue = new BigNumber(inputValue)
      const fromAmountSelectedCurrency = bigValue.multipliedBy(tokenFrom?.priceUSD)
      setFromTokenUsdPrice(fromAmountSelectedCurrency)
    }
  }, [tokenFrom?.priceUSD, inputValue])

  if (!currentToken?.id) {
    return null
  }

  const networkConfig = getNetworkConfig(currentToken.network)
  const styleIcon = getInlineNetworkIconStyle(currentToken?.network, networkConfig)
  const title = formVariant === FormVariants.market ? 'From' : 'You Pay'
  return (
    <div className="tradeform-field tradeform-field--from">
      <div className="tradeform-field__header">
        <div className="tradeform-field__title">{title}</div>
        <div className="tradeform-field__value">
          <BalanceButton
            tokenFrom={tokenFrom}
            walletNetwork={walletNetwork}
            account={account}
            userBalance={userBalance}
            onPercentButtonClick={onPercentButtonClick}
          />
        </div>
      </div>
      <div
        className={classNames('tradeform-field__body', {
          'tradeform-field__body--selector': tradeType === 'Buy',
        })}>
        <div
          className={classNames('tradeform-combo', {
            'tradeform-combo--open': isDropDownOpened,
            'tradeform-combo--focus': isFocus,
            'tradeform-combo--selector': tradeType === 'Buy',
          })}>
          {tradeType !== 'Buy' ? (
            <div className="tradeform-combo__token">
              <div className="tradeform-token">
                <div
                  className={classNames(
                    'tradeform-token__icon',
                    'token-ico',
                    'token-ico--sm',
                    currentToken?.network ? `token-ico--network-${currentToken?.network} ` : ''
                  )}
                  style={styleIcon}>
                  {tokenFrom?.id && (
                    <IconTokenWrapper
                      logoURI={tokenFrom.logoURI}
                      symbols={tokenFrom.symbols}
                      className="token-ico__image"
                    />
                  )}
                </div>
                {tokenFrom?.id && (
                  <span className="tradeform-token__name" title={getMarketDisplayName(tokenFrom)}>
                    <span className="caption">{getMarketDisplayName(tokenFrom)}</span>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <Suspense fallback={<div />}>
              <DropdownForm
                tradeType={tradeType}
                onDropDownStateChange={setIsDropDownOpened}
                onChangeToken={(): void => setInputValue('')}
                network={currentToken.network}
                isChangeQuoteToken
                tradeformType={formVariant}
                token={tokenFrom}
              />
            </Suspense>
          )}
          <div className="tradeform-combo__query">
            {fromTokenUsdPrice.gt(0) && inputValue.length > 0 && (
              <div className="tradeform-field__price">
                {financialFormat(fromTokenUsdPrice, { prefix: '~$' })}
              </div>
            )}
            <InputFrom
              onChange={setInputValue}
              onBlur={(): void => setIsFocus(false)}
              onFocus={(): void => setIsFocus(true)}
              value={inputValue}
              disabledInput={disabledInput}
            />
          </div>
        </div>
      </div>
      <div className="tradeform-field__footer">
        <PercentButtons
          onPercentButtonClick={onPercentButtonClick}
          tokenFrom={tokenFrom}
          userBalance={userBalance}
          disabled={disabledInput || (disabled && userBalance.gt(0))}
        />
      </div>
    </div>
  )
}

export default React.memo(FormFrom, (prevProps, nextProps) => {
  if (prevProps.account !== nextProps.account) {
    return false
  }
  if (prevProps.tokenFrom?.id !== nextProps.tokenFrom?.id) {
    return false
  }
  if (prevProps.tokenFrom?.priceUSD !== nextProps.tokenFrom?.priceUSD) {
    return false
  }
  if (prevProps.walletNetwork !== nextProps.walletNetwork) {
    return false
  }
  if (prevProps.userBalance.toFixed() !== nextProps.userBalance.toFixed()) {
    return false
  }
  if (prevProps.tradeType !== nextProps.tradeType) {
    return false
  }
  if (prevProps.isEmpty !== nextProps.isEmpty) {
    return false
  }
  if (prevProps.disabled !== nextProps.disabled) {
    return false
  }
  if (prevProps.disabledInput !== nextProps.disabledInput) {
    return false
  }

  return true
})
